<template>
  <router-view :key="$route.params.sector_id" />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  async mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Menu Manager',
      to: { name: 'menu-sector-listing' },
    });
  },
  methods: {
    ...mapMutations('menus', ['setPageYOffset']),
    handleScroll() {
      this.setPageYOffset(window.pageYOffset);
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style></style>
